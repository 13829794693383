import { setupReactorStations } from "./station";
import { handleAudioPlayer } from "./audio";
import { setupClock } from "./clock";
import { siteLoad } from "./siteload";

const getDisplayElements = () => ({
  playerVisuals: document.querySelector("body"),

  loadScreen: document.querySelector(".loadscreen"),
  loadText: document.querySelector(".load-text"),
  loadNote: document.querySelector(".load-note"),

  backgroundCredit: document.querySelector(".background-credit"),

  streamerName: document.querySelector(".dj"),

  audioSource: document.querySelector(".audio-source"),
  audioPlay: document.querySelector(".controls-playback"),

  backgroundSrc: document.querySelector(".track-src"),
  backgroundVideo: document.querySelector(".track-visualization"),
  albumArtImage: document.querySelector(".album-art-image"),
  albumName: document.querySelector(".album-name"),

  notes: document.querySelector(".notes"),

  rose: document.querySelector(".rose-spinner"),

  trackProgress: document.querySelector(".time-current"),
  trackPlayTime: document.querySelector(".time-total"),
  song: document.querySelector(".track-song"),
  artist: document.querySelector(".track-artist"),
  streamQuality: document.querySelector(".mount-name"), // "128kbps MP3"
  bitrate: document.querySelector(".mount-bitrate"), // "128kbps MP3"

  clock: document.querySelector(".time"),
});

(async () => {
  const elements = getDisplayElements();

  siteLoad(elements);

  setupClock(elements);

  await setupReactorStations(elements);
  handleAudioPlayer(elements);
})();
