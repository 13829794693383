export const setAudioURI = (audioSource, audioURI) => {
  audioSource.src = audioURI;
};

export let hasStarted;

export const audioControlPlay = async (elements) => {
  try {
    // catch DOMException where chrome prevents autoplaying audio without
    // a user interaction. ensure pause button doesn't incorrectly show
    // when playback fails
    if (!hasStarted) {
      await elements.audioSource.play();
      hasStarted = true;
      elements.audioSource.muted = false;
      elements.audioPlay.classList.remove("controls-playback", "paused");
      elements.audioPlay.classList.add("controls-playback", "playing");
    } else {
      elements.audioSource.muted = false;
      elements.audioPlay.classList.remove("controls-playback", "paused");
      elements.audioPlay.classList.add("controls-playback", "playing");
      //console.log("You unmuted");
    }
  } catch (e) {
    console.info("Could not play audio element.");
    console.info(e);
  }
};

export const audioControlPause = (elements) => {
  if (elements.audioSource.muted) return;

  //elements.audioSource.pause();
  elements.audioSource.muted = true;
  //console.log("You muted");
  elements.audioPlay.classList.remove("controls-playback", "playing");
  elements.audioPlay.classList.add("controls-playback", "paused");
};

export const audioControlSetVolume = (audioSource, volume) => {
  audio.volume = clamp(volume, 0, 1.0);
};

export const isPlaying = (elements) => !elements.audioSource.paused;

export const handleAudioPlayer = (elements) => {
  // Wait till we can play the audio
  // elements.audioSource.addEventListener("canplay", _e => {
  //   audioControlPlay(elements);
  // });

  // Wait till we can play the audio
  elements.audioPlay.addEventListener("click", (_e) => {
    if (!hasStarted) {
      console.log("::: Playing for first time :::");
      audioControlPlay(elements);
    } else if (elements.audioSource.muted) {
      console.log("::: Unmuted :::");
      audioControlPlay(elements);
    } else if (!elements.audioSource.muted) {
      console.log("::: Muted :::");
      audioControlPause(elements);
    }
  });
};
