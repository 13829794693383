import { animateTextToHTML } from "./util";

export const siteLoad = elements => {

	readLogIn(elements);

	manageBackgroundSize(elements);

	loadBackground(elements);

	primeToggle(elements);
}

const loadText = `The copyrighted Music / Characters / Artwork on this station are separately owned by the authors.

Public performance using this station is prohibited.

(C) 20XX Arte Et Labore.

---

( PRESS TO START )`
;

const loadNote = "W A R N I N G";

const readLogIn = elements => {
	animateTextToHTML(elements.loadText, loadText, { 1 : elements.loadText });
	animateTextToHTML(elements.loadNote, loadNote, { 1 : elements.loadNote });

	let root = document.documentElement;

	elements.loadScreen.addEventListener("mousedown", e => {
		console.log("::: Reactor Radio :::");

		// Close the title screen
		elements.loadScreen.classList.add("closed");

		// Fade in radio elements
		fadeIn(elements.playerVisuals);
	});
};

const fadeIn = visuals => {
	visuals.classList.add("ready-to-fadein");
};

let colorOptions = [
	"#fd7700",
	"#ff82ef",
	"#8A8FE2",
	"#88e9ff",
	"#9cff90",
	"#fcff5e",
	"#DDDDDD"
];

let currentColor = 0;

let backgroundSources = {
	// 1 : {
	// 	src : "../media/background1.mp4",
	// 	opacity: 1,
	// 	creator: "@4nt1r34l"
	// },
	0 : {
		src : "../media/background2.mp4",
		opacity: .2,
		creator: "@cercueii",
		socialURL: "https://twitter.com/cercueii"
	},
	1 : {
		src : "../media/background3.mp4",
		opacity: .3,
		creator: ["@cercueii", "@cute_hospital"],
		socialURL: ["https://twitter.com/cercueii", "https://twitter.com/cute_hospital"]
	},
	2 : {
		src : "../media/background4.mp4",
		opacity: 1,
		creator: "@GhstSnks",
		socialURL: "https://twitter.com/GhstSnks"
	},
	3 : {
		src : "../media/background5.mp4",
		opacity: .4,
		creator: "@om_neb",
		socialURL: "https://twitter.com/om_neb"
	},
	4 : {
		src : "../media/background6.mp4",
		opacity: 1,
		creator: "@GhstSnks",
		socialURL: "https://twitter.com/GhstSnks"
	},
	5 : {
		src : "../media/background7.mp4",
		opacity: 1,
		creator: "@Integra",
		socialURL: "https://twitter.com/IntegraTunes"
	},
	6 : {
		src : "../media/background8.mp4",
		opacity: .8,
		creator: "@GhstSnks",
		socialURL: "https://twitter.com/GhstSnks"
	},
	7 : {
		src : "../media/background9.mp4",
		opacity: .7,
		creator: "@GhstSnks",
		socialURL: "https://twitter.com/GhstSnks"
	},
	8 : {
		src : "../media/background10.mp4",
		opacity: .6,
		creator: "@GhstSnks",
		socialURL: "https://twitter.com/GhstSnks"
	},
	9 : {
		src : "../media/background11.mp4",
		opacity: .6,
		creator: "@GhstSnks",
		socialURL: "https://twitter.com/GhstSnks"
	},
	10 : {
		src : "../media/background12.mp4",
		opacity: .7,
		creator: ["@lilikoi", "@Boocanan"],
		socialURL: ["https://twitter.com/lilikoi_jp", "https://twitter.com/designbyboo"]
	},
	11 : {
		src : "../media/background13.mp4",
		opacity: .8,
		creator: "@Articution",
		socialURL: "https://twitter.com/articution"
	},
	12 : {
		src : "../media/background14.mp4",
		opacity: .8,
		creator: "@Articution",
		socialURL: "https://twitter.com/articution"
	},
	13 : {
		src : "../media/background15.mp4",
		opacity: .8,
		creator: "@Articution",
		socialURL: "https://twitter.com/articution"
	},
	14 : {
		src : "../media/background16.mp4",
		opacity: .7,
		creator: "@Integra",
		socialURL: "https://twitter.com/IntegraTunes"
	}
}

export const loadBackground = elements => {
	// Set the background to be a random one from our available videos
	let chosenIndex = Math.floor( Math.random() * Object.keys(backgroundSources).length );

	// Set the background
	elements.backgroundSrc.src = backgroundSources[chosenIndex].src;
	elements.backgroundSrc.parentNode.style.opacity = backgroundSources[chosenIndex].opacity;

	// Start the video
	elements.backgroundSrc.parentNode.load();
	elements.backgroundSrc.parentNode.play()

	// Add credit
	var attribution = "";

	if ( Array.isArray(backgroundSources[chosenIndex].creator ) == false) {
		attribution = `<a href="` + backgroundSources[chosenIndex].socialURL + `" target="_blank">` + backgroundSources[chosenIndex].creator + `</a>` + ` `;
	}
	else {
		for (var i = 0; i < backgroundSources[chosenIndex].creator.length; i++) {
			attribution += `<a href="` + backgroundSources[chosenIndex].socialURL[i] + `" target="_blank">` + backgroundSources[chosenIndex].creator[i] + `</a>` + ` `;
		}
	}

	elements.backgroundCredit.innerHTML = attribution;
}	

export const manageBackgroundSize = elements => {		

	var resizeTimer;

	window.onresize = function() {

		// Debounce
		clearTimeout(resizeTimer);

		resizeTimer = setTimeout(function() {
			elements.backgroundVideo.height = window.innerHeight;
			elements.backgroundVideo.width = window.innerWidth;
		}, 300);
	}

	window.onresize(); // called to initially set the height.
}

export const primeToggle = elements => {
	let root = document.documentElement;
	
	// Turning off color
	elements.rose.addEventListener("mousedown", e => {
		currentColor++;
	  	root.style.setProperty('--primary', (colorOptions[currentColor % colorOptions.length]).toString() );
	});
}




