const getClockString = () => {
  let d = new Date();

  return Intl.DateTimeFormat(undefined, {
    hour: "numeric",
    minute: "numeric",
  }).format(d);
};

export const setupClock = elements => {
  updateClock(elements.clock);
  setInterval(() => updateClock(elements.clock), 1000);
};

const updateClock = clock => {
  clock.textContent = getClockString();
};
