import { fancyTimeFormat } from "./util";
import { animateTextToHTML } from "./util";
import { setAudioURI, isPlaying } from "./audio";

const REST_ENDPOINT = "https://reactor.lio.systems/api/nowplaying";
const WS_ENDPOINT = (window.location.protocol === "https:" ? "wss" : "ws") + "://reactor.lio.systems/api/nowplaying";

export const setupReactorStations = async elements => {
  try {
    const response = await fetch(REST_ENDPOINT);
    // get array of all stations
    const allStations = await response.json();

    if (
      !allStations || // failed to parse json / response malformed
      !allStations.length // no stations found
      //|| !allStations.filter(s => s.now_playing.song.text !== "Stream Offline") // no stations are playing
      //   .length
    ) {
      displayErrorMessage(elements);
      return;
    }

    // find first station that is currently running a live broadcast,
    // otherwise default to station 1
    const currentStation =
      allStations.find(s => s.live.is_live) ?? allStations[0];
    setupPlayer(elements, currentStation);
  } catch (err) {
    displayErrorMessage(elements);
    console.error(err);
  }
};

const displayErrorMessage = elements => {
  elements.song.innerHTML =
    "REACTOR is currently offline. Please try again later.";
  elements.artist = "";
};

const updateDisplay = (elements, stationData, { newSong }) => {
  // Set the data
  // STREAMER
  if (stationData.live.is_live) {
    // Live streamer
    animateTextToHTML(elements.streamerName, stationData.live.streamer_name + " / LIVE", { 1 : elements.streamerName });
    // elements.streamerName.innerHTML = stationData.live.streamer_name + " / LIVE";
  } else {
    // On rotation streamer
    animateTextToHTML(elements.streamerName, "AEL ROTATION", { 1 : elements.streamerName });
    // elements.streamerName.innerHTML = "AEL ROTATION";
  }

  // STREAMING RANDOM INFO
  if (stationData.song_history) {
    let list = "/ ";
    for (var i = 0; i < stationData.song_history.length; i++) {
      list += stationData.song_history[i].song.title + " / ";
    }
    animateTextToHTML(elements.notes, list, { 0 : elements.notes });
  }

  // TRACK PROGRESS
  if (newSong) {
    // store raw elapsed time in data attribute
    // so it can be incremented locally by the timer.
    // not super efficient but since we're modifying the DOM
    // every second anyway it's not terrible
    updateElapsedRaw(elements, stationData.now_playing.elapsed, { newSong });
    updateElapsedDisplay(elements);
    elements.trackPlayTime.innerHTML = fancyTimeFormat(
      stationData.now_playing.duration
    );
  }

  // TRACK NAME
  // elements.song.innerHTML = "" + stationData.now_playing.song.title + " — ";
  // elements.artist.innerHTML = "" + stationData.now_playing.song.artist;

  animateTextToHTML(elements.song, stationData.now_playing.song.title, { 2: elements.song });
  animateTextToHTML(elements.artist, stationData.now_playing.song.artist, { 3 : elements.artist });

  // TRACK DATA
  elements.streamQuality.innerHTML = stationData.station.mounts[0].name;
  // elements.bitrate.innerHTML = "BIT RATE: " + stationData.station.mounts[0].bitrate;

  // ALBUM ART
  // if (stationData.now_playing.song.art) {
  //   elements.albumArtImage.src = stationData.now_playing.song.art;
  // }

  // ALBUM NAME
  // if (stationData.now_playing.song.albumName) {
  //   animateTextToHTML(elements.albumName, stationData.now_playing.song.album, { 4 : elements.albumName });
  // }
  // else {
  //   animateTextToHTML(elements.albumName, "single", { 4 : "single" });
  // }
};

const updateElapsedRaw = (elements, elapsed, { newSong }) => {
  // only update if new elapsed time is greater, so time doesn't move backwards
  // (happens because rest api gives a later time than the sockets api)
  if (
    // update if attr doesn't exist yet
    // or new time is after current time
    // or a new song is playing
    !elements.trackProgress.dataset.elapsed ||
    parseInt(elements.trackProgress.dataset.elapsed) < elapsed ||
    newSong
  )
    elements.trackProgress.setAttribute("data-elapsed", elapsed);
};

const updateElapsedDisplay = elements => {
  elements.trackProgress.innerHTML = fancyTimeFormat(
    elements.trackProgress.dataset.elapsed
  );
};

const setupPlayer = (elements, stationData) => {
  // Fields
  // let liveStatus;
  // let trackArt; // This could be overlayed on the video for visualization effect, maybe with a weird blend mode

  const currentStationAudioSource = stationData.station.listen_url;
  setAudioURI(elements.audioSource, currentStationAudioSource);
  updateDisplay(elements, stationData, { newSong: true });
  setupWebsocketConn(elements, stationData);

  setInterval(() => {
    // if (isPlaying(elements)) {
      updateElapsedRaw(
        elements,
        parseInt(elements.trackProgress.dataset.elapsed) + 1,
        { newSong: false }
      );
      updateElapsedDisplay(elements);
    // }
  }, 1000);
};

const setupWebsocketConn = (elements, stationData) => {
  const socket = new WebSocket(
    // WS_ENDPOINT + "/" + stationData.station.shortcode
    (window.location.protocol === "https:" ? "wss" : "ws") + "://reactor.lio.systems/api/live/nowplaying/reactor_runtime_test"
    // This assumes we have a single station and it's no longer the case. Build a selector that gets each existing station and on change push the shortcode data to this socket setup
  );

  socket.addEventListener("open", _e => {
    console.info("Connected to REACTOR endpoint.");
  });

  socket.addEventListener("close", _e => {
    console.info("Disconnected from REACTOR endpoint.");
  });

  socket.addEventListener("error", e => {
    console.info("Disconnected from REACTOR endpoint.");
    console.error("Error:", e);
  });

  let lastSongId = 0;

  socket.addEventListener("message", e => {
    const stationData = JSON.parse(e.data);
    // only update time display if last song's id is different from
    // current song's id, i.e. the next track is playing.
    // this is to prevent jumps in time due to server-client latency
    // while keeping track changes synchronised
    const newSong = lastSongId !== stationData.now_playing.sh_id;

    updateDisplay(elements, stationData, { newSong });
    lastSongId = stationData.now_playing.sh_id;

    // if currently connected to a live broadcast,
    // and broadcast ends
    if (stationData.id > 1 && !stationData.live.is_live) {
      // close the socket and re-poll the rest api for live stations
      socket.close();
      setupReactorStations(elements);
    }
  });
};
